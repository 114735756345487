'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;

import '../css/styles.scss';
require('bootstrap');

import adminMenu from '@elasticms/admin-menu';
import euCookie from "./eu-cookie";
import back2top from "./back2top";
import toc from "./toc";
import externalLink from "./external-link";
import wysiwygContent from "./wysiwyg-content";

import Swiper, { Navigation, Pagination } from 'swiper';
adminMenu();
euCookie();
back2top();
toc();
externalLink();

$(document).on('click', '.card-hover', function() {
    location = $(this).find('a:not(.emsch)').attr('href');
});

function jsSameHeight() {
    let jsSameHeightArray = {};
    $('.js-same-height').css('height', 'auto');

    if ($(window).innerWidth() < 768) return;

    $('.js-same-height').each(function() {
        let dataKey = $(this).data('key');

        if (!jsSameHeightArray[dataKey] || jsSameHeightArray[dataKey] < $(this).outerHeight()) {
            jsSameHeightArray[dataKey] = $(this).outerHeight();
        }
    });

    for (const [key, value] of Object.entries(jsSameHeightArray)) {
        $('[data-key="'+ key +'"]').css('height', value);
    }
}

function svgResize() {
    $('svg.chart').each(function() {
        let availableWidth = Math.min($(this).parent().width(), 350);
        $(this).css('width', availableWidth);

        let svgViewboxHeight = parseInt($(this).attr('viewBox').split(' ')[3]);
        
        let supposedHeight = svgViewboxHeight * (availableWidth / 300);
        $(this).css('height', supposedHeight);
    });
}

$(document).ready(function() {
    jsSameHeight();
    svgResize();
    wysiwygContent();
    const swiper = new Swiper('.swiper', {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
});

$(window).on('resize', function() {
    jsSameHeight();
    svgResize();
});

// Object.keys polyfill
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys#polyfill
if (!Object.keys) {
    Object.keys = (function() {
        'use strict';
        var hasOwnProperty = Object.prototype.hasOwnProperty,
            hasDontEnumBug = !({ toString: null }).propertyIsEnumerable('toString'),
            dontEnums = [
                'toString',
                'toLocaleString',
                'valueOf',
                'hasOwnProperty',
                'isPrototypeOf',
                'propertyIsEnumerable',
                'constructor'
            ],
            dontEnumsLength = dontEnums.length;
  
        return function(obj) {
            if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
                throw new TypeError('Object.keys called on non-object');
            }
  
            var result = [], prop, i;
  
            for (prop in obj) {
                if (hasOwnProperty.call(obj, prop)) {
                    result.push(prop);
                }
            }
  
            if (hasDontEnumBug) {
                for (i = 0; i < dontEnumsLength; i++) {
                    if (hasOwnProperty.call(obj, dontEnums[i])) {
                        result.push(dontEnums[i]);
                    }
                }
            }
        
            return result;
        };
    }());
}

// Object.entries polyfill
// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#polyfill
if (!Object.entries) {
    Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
  
        return resArray;
    };
}

